import { axiosPrivate } from "./axios";

export const brochurefromsubmitByApi = async (payloadData) => {
//   let res = await axiosPrivate.post("frontend/brochure", payloadData);
//   return res.data;
  let res = await axiosPrivate.post("/frontend/brochure", payloadData, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return res.data;
};
