import React from "react";
import Carouselll from "../Slider";
import About from "../About";
import Sciencework from "../Sciencework";
import VideoGallery from "../VideoGallery";
import WhyUs from "../WhyUs";
import Jumbotron from "../Jumbotron";
import BrochureForm from "../BrochureForm";
// import Footer from "../Footer";
const Home = () => {
  return (
    <>
      <Carouselll />
      <About />
      <Sciencework />
      <VideoGallery />
      <WhyUs />
      {/* <BrochureForm /> */}
      <Jumbotron />
      {/* <Footer /> */}
    </>
  );
};

export default Home;
