import React, { useState, useEffect } from "react";
import { Carousel } from "antd";
import image1 from "../../image/whyus-1.JPG";
import image2 from "../../image/whyus-2.jpg";
import image3 from "../../image/whyus.jpg";
import Zoom from "react-reveal/Zoom";
import "./whyus.css";
import Rotate from "react-reveal/Rotate";
import strings from "../../strings";
import { whyusByApi } from "../Helper/whyus";
import { Link } from "react-router-dom";

const WhyUs = () => {
  const [loading, setloading] = useState(false);
  const [whyusdata, setwhyusdata] = useState([]);
  // const Urlimg = "https://buildtectapi.devminds.co.in/Images/";
  const Urlimg = strings.API_ROUTE + "/Images/" ;

  useEffect(() => {
    onfunwhyus();
  }, []);
  const onfunwhyus = () => {
    setloading(true);
    whyusByApi().then(
      (res) => {
        // console.log("res", res);
        // console.log("whyus res==>", res);
        setwhyusdata(res);
      },
      (err) => {
        // console.log(err);
      }
    );
  };
  // console.log("whyustextcheck", whyusdata);
  return (
    <>
      <div className=" box-we" style={{ backgroundColor: "#5d5757" }}>
        <div className="container ">
          <div className="row ">
            <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-12">
              <div>
                {whyusdata && whyusdata.length > 0 ? (
                  <Carousel autoplay className="sliderWhyUs">
                    {whyusdata.map((e, index) => {
                      return (
                        <>
                          <div key={index}>
                            <Zoom left cascade duration={1000}>
                              <img
                                src={Urlimg + e.image1}
                                className="imgSlider2"
                                // alt="New York"
                                alt={`Why Us Image ${index + 1}`}
                              />
                            </Zoom>
                            <div className="carousel-caption">
                              {/* <!-- <p>UPVC sliding  windows.</p> --> */}
                            </div>
                          </div>
                          ;
                        </>
                      );
                    })}
                  </Carousel>
                ) : (
                  <>
                    <Carousel autoplay>
                      <div>
                        <img
                          src={image1}
                          className="imgSlider2"
                          alt="New York"
                        />
                        <div className="carousel-caption">
                          {/* <!-- <p>UPVC sliding  windows.</p> --> */}
                        </div>
                      </div>
                      <div>
                        <img
                          src={image2}
                          className="imgSlider2"
                          alt="Los Angeles"
                        />
                        <div className="carousel-caption">
                          {/* <!-- <p>keep out street noise.</p> --> */}
                        </div>
                      </div>
                      <div>
                        <img
                          src={image3}
                          className="imgSlider2"
                          alt="Chicago"
                        />
                        <div className="carousel-caption">
                          {/* <!-- <p>Windows.</p> --> */}
                        </div>
                      </div>
                    </Carousel>
                    ;
                  </>
                )}
                {/* <div id="demo1" className="carousel slide" data-ride="carousel">
                  <ul className="carousel-indicators">
                    <li
                      data-target="#demo1"
                      data-slide-to="0"
                      className="active"
                    ></li>
                    <li data-target="#demo1" data-slide-to="1"></li>
                    <li data-target="#demo1" data-slide-to="2"></li>
                  </ul>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img src={image1} className="imgSlider2" alt="New York" />
                      <div className="carousel-caption"> */}
                {/* <!-- <p>UPVC sliding  windows.</p> --> */}
                {/* </div>
                    </div>
                    <div className="carousel-item ">
                      <img
                        src={image2}
                        className="imgSlider2"
                        alt="Los Angeles"
                      />
                      <div className="carousel-caption"> */}
                {/* <!-- <p>keep out street noise.</p> --> */}
                {/* </div>
                    </div>
                    <div className="carousel-item">
                      <img src={image3} className="imgSlider2" alt="Chicago" />
                      <div className="carousel-caption"> */}
                {/* <!-- <p>Windows.</p> --> */}
                {/* </div>
                    </div>
                  </div>
                  <a
                    className="carousel-control-prev"
                    href="#demo1"
                    data-slide="prev"
                  >
                    <span className="carousel-control-prev-icon"></span>
                  </a>
                  <a
                    className="carousel-control-next"
                    href="#demo1"
                    data-slide="next"
                  >
                    <span className="carousel-control-next-icon"></span>
                  </a>
                </div> */}
                {/* <!-- <img src="./images/whyus.jpg" style="width:100%">  */}
                {/* <h5>Sliding Door</h5> --> */}
              </div>
            </div>

            <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12">
              <div className="text-why">
                <Rotate bottom left>
                  <h2 className="text-white"> Why Us?</h2>
                </Rotate>
                <span className="text-white " style={{ fontSize: "18px" }}>
                {whyusdata.length > 0 && whyusdata[0].title1}
                </span>
                <div style={{ marginTop: "30px" }}>
                  <Link to="/contact">
                    <button
                      type="button"
                      className="btn btn-outline-warning text-white "
                    >
                      learn More
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyUs;
