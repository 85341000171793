import React, { useEffect, useState } from "react";
import { Form, Input, InputNumber, Button } from "antd";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhoneVolume,
} from "@fortawesome/free-solid-svg-icons";
import { jumbotronByApi } from "../Helper/jumbotron";

const { TextArea } = Input;

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState("large");
  const [contactadress, setContactadress] = useState([]);
  const [formValues, setFormValues] = useState({
    from_name: "",
    email: "",
    mobileNoo: "",
    cityName: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const MessagesendEmail = () => {
    setLoading(true);
    // console.log("formValues MessagesendEmail =>", formValues);

    if (
      formValues.message &&
      formValues.from_name &&
      formValues.email &&
      formValues.cityName &&
      formValues.mobileNoo
    ) {
      const serviceID = "service_55oi00g";
      const templateID = "template_bbqn7yk";
      const APIkeys = "eM1imwF23bPFETC4Q";
      const formElement = document.getElementById("form");

      emailjs
        .sendForm(serviceID, templateID, formElement, APIkeys, formValues)
        .then(
          () => {
            // alert("Sent!");
            toast.success("Email Send successfully!", {
              position: "top-right",
              autoClose: 5000, // Close the toast after 5 seconds
            });
            setLoading(false);
            // console.log("Success send email");
            document.getElementById("form").reset();
            formElement.reset();
          },
          (err) => {
            alert(JSON.stringify(err));
            toast.error("Email Not Send!", {
              position: "top-right",
              autoClose: 5000, // Close the toast after 5 seconds
            });
          }
        );
    }
  };

  useEffect(() => {
    contactfunadress();
  }, []);
  const contactfunadress = () => {
    // setloading(true);
    jumbotronByApi().then(
      (res) => {
        // console.log("res", res);
        // console.log("contact res==>", res);
        setContactadress(res);
      },
      (err) => {
        // console.log(err);
      }
    );
  };
  return (
    <div className="contactbg">
      <div className="container pt-5">
        <h4
          className="text-white contactmaintitle"
          style={{
            paddingLeft: "11px",
            paddingBottom: "50px",
            fontFamily: "emoji",
          }}
        >
          Windows For Life - contact us!
        </h4>
      </div>
      <div className="container">
        <Form
          layout="vertical"
          name="basic"
          labelCol={{
            span: 8,
          }}
          onFinish={MessagesendEmail}
          autoComplete="off"
          id="form"
        >
          <div className="row">
            <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-12">
              <div className="container">
                <div className="form-group">
                  <label className="text-white inputtitle inputtitle">
                    First Name:
                  </label>
                  <Form.Item
                    name="from_name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Name!",
                      },
                    ]}
                  >
                    <Input
                      className="form-control inputContact"
                      name="from_name"
                      onChange={handleInputChange}
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <label className="text-white inputtitle">Email:</label>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Email!",
                      },
                      { type: "email", message: "please enter a valid email" },
                    ]}
                  >
                    <Input
                      className="form-control inputContact"
                      name="email"
                      onChange={handleInputChange}
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <label className="text-white inputtitle">Mobile:</label>
                  <Form.Item
                    name="mobileNoo"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Mobile Number!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          // console.log(
                          //   "String(value).length",
                          //   String(value).length
                          // );
                          if (!value || String(value).length === 10) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Please input 10 numbers!")
                          );
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      className="form-control inputContact"
                      name="mobileNoo"
                      onChange={(value) =>
                        handleInputChange({
                          target: { name: "mobileNoo", value },
                        })
                      }
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <label className="text-white inputtitle">City:</label>
                  <Form.Item
                    name="cityName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Enter City!",
                      },
                    ]}
                  >
                    <Input
                      className="form-control inputContact"
                      name="cityName"
                      onChange={handleInputChange}
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <label className="text-white inputtitle">Message:</label>
                  <Form.Item
                    name="message"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Enter Message!",
                      },
                    ]}
                  >
                    <TextArea
                      className="form-control inputContact"
                      name="message"
                      onChange={handleInputChange}
                    />
                  </Form.Item>
                </div>
                <Button
                  type="primary"
                  loading={loading}
                  htmlType="submit"
                  className="btn btn-primary"
                  size={size}
                >
                  Submit
                </Button>
              </div>
            </div>

            <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-12">
              <br />
              <br />
              <div className="container">
                <h6 className="contacttitle">Get in touch</h6>
              </div>
              <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-12">
                <div className="contacttextbackground">
                  <h5 className="maintitle">
                    LOCATION <FontAwesomeIcon icon={faLocationDot} />
                  </h5>

                  {contactadress && contactadress.length > 0 ? (
                    contactadress.map((e, index) => {
                      return (
                        <>
                          <p className="textincontactp">{e.address}</p>
                        </>
                      );
                    })
                  ) : (
                    <p>
                      Studio buildtech Shop No: S 01 & 102, T D Plaza, 585 E
                      Ward, Shahupuri, Vyapari Peth, Kolhapur 416001
                    </p>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-12">
                <div className="contacttextbackground">
                  <h6 className="maintitle">
                    CONTACT US <FontAwesomeIcon icon={faPhoneVolume} />
                  </h6>
                  <p className="textincontactp">
                    Phone: +91 9257795656 <br /> +91 9257785656
                    <br /> +91 9572123737 <br />
                    Email: studio.buildtech@gmail.com
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12">
              <div className="container">
                <div className="form-group buttonsubmistyle">
                  <Button
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                    className="btn btn-primary"
                    size={size}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div> */}
          </div>
          {/* Add ToastContainer from react-toastify */}
          <ToastContainer />
        </Form>
      </div>
    </div>
  );
};

export default Contact;
