import "./App.css";
import Header from "./components/Header/index";
// import Carouselll from "./components/Slider/index";
import About from "./components/About/index";
// import Sciencework from "./components/Sciencework/index";
// import VideoGallery from "./components/VideoGallery/index";
// import WhyUs from "./components/WhyUs/index";
// import Jumbotron from "./components/Jumbotron/index";
import Footer from "./components/Footer/index";
import Home from "./components/Home/index";
import Contact from "./components/Contact/index";
import Mainpage from "./components/Mainpage/index";
import Product from "./components/Product/index";
import ReactAudioPlayer from "react-audio-player";
import { useLocation } from "react-router-dom";
import Audiosong from "./image/audio/backgroundmusic.mp3";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import RightClickBlocker from "./components/RightClickBlocker";

function App() {
  const location = useLocation();
  return (
    <div className="selectable-text">
      <RightClickBlocker />
      {location.pathname === "/" ? null : (
        <div className="header-fix">
          <Header />
        </div>
      )}
      <Routes>
        <Route path="/" element={<Mainpage />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/product/:id" element={<Product />} />
      </Routes>
      {/* {location.pathname === "/Home" ? (
        <ReactAudioPlayer src={Audiosong} autoPlay loop />
      ) : (
        ""
      )} */}
      {location.pathname === "/" ? null : <Footer />}
      <div></div>
      <div className="whatsappmaindiv">
        <div className="bottom-whatsapp">
          <a href="https://wa.me/919257795656" target="_blank">
            <FontAwesomeIcon icon={faWhatsapp} className="whatsappicon" />
            <i
              // className="fa fa-whatsapp text-white"
              style={{ fontSize: "25px" }}
            ></i>
          </a>
          <span className="on-hover-text">
            <div className="arrow"></div>
            <a href="https://wa.me/919257795656" target="_blank">
              Contact us
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}

export default App;
