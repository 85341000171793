import React, { useState, useEffect } from "react";
import "./video.css";
import { videogalleryByApi } from "../Helper/vedio";
import Slide from "react-reveal/Slide";
import strings from "../../strings";
const VideoGallery = () => {
  const [loading, setloading] = useState(false);
  const [Videodata, setVideodata] = useState([]);
  // const Urlimg = "https://buildtectapi.devminds.co.in/Images/";
  const Urlimg = strings.API_ROUTE + "/Images/" ;
  useEffect(() => {
    onfunVideo();
  }, []);
  const onfunVideo = () => {
    setloading(true);
    videogalleryByApi().then(
      (res) => {
        setVideodata(res);
      },
      (err) => {
        // console.log(err);
      }
    );
  };
  return (
    <>
      <div
        className=" box-we"
        style={{ backgroundColor: "#cbcbcb", paddingTop: "31px" }}
      >
        <div className="container">
          <h2 className="text-white"> Video Gallery </h2>
        </div>
        <br />
        <div className="container">
          <div className="row ">
            {Videodata && Videodata.length > 0 ? (
              Videodata.map((e, index) => {
                return (
                  <div className="col-lg-3 col-xl-3 col-md-6 col-sm-12 col-12" key={index}>
                    <div className="vedio-div">
                      <Slide right>
                        <iframe
                          className="vedio-box"
                          src={e.videourl}
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen
                        ></iframe>
                      </Slide>
                      {/* <!-- <iframe  src="https://www.youtube.com/embed/oMlFoHR5GWE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> --> */}
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                <div className="col-lg-3 col-xl-3 col-md-6 col-sm-12 col-12">
                  <div className="vedio-div">
                    <iframe
                      className="vedio-box"
                      src="https://www.youtube.com/embed/4ChIHhq3ZNg"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                    {/* <!-- <iframe className="vedio-box" src="https://www.youtube-nocookie.com/embed/odyX1Hf6KKA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> --> */}
                  </div>
                </div>
                <div className="col-lg-3 col-xl-3 col-md-6 col-sm-12 col-12">
                  <div className="vedio-div">
                    <iframe
                      className="vedio-box"
                      src="https://www.youtube.com/embed/XsfESKlOYFI"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                    {/* <!-- <iframe  src="https://www.youtube.com/embed/oMlFoHR5GWE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> --> */}
                  </div>
                </div>
                <div className="col-lg-3 col-xl-3 col-md-6 col-sm-12 col-12">
                  <div className="vedio-div">
                    <iframe
                      className="vedio-box"
                      src="https://www.youtube.com/embed/RE-knNV4xNQ"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                    {/* <!-- <iframe className="vedio-box" src="https://www.youtube-nocookie.com/embed/CywFhMb5GTw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> --> */}
                  </div>
                </div>
                <div className="col-lg-3 col-xl-3 col-md-6 col-sm-12 col-12">
                  <div className="vedio-div">
                    <iframe
                      className="vedio-box"
                      src="https://www.youtube.com/embed/FEjNov0Vf6M"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoGallery;
