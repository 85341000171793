import { axiosPrivate } from "./axios";

export const fetchAllCategoriesByApi = async () => {
  let res = await axiosPrivate.get("/frontend/fetchAllCategories", {});
  return res.data;
};

export const fetchProductById = async (payload) => {
  let res = await axiosPrivate.post("/frontend/fetchProductById", payload);
  return res.data;
};
