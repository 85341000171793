import React from "react";
import { Button, Col, Layout, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import "./mainpage.css";
import logo from "../../image/logo_black.png";
import Facebookicon from "../../image/facebook icon filled.2690de2416052e1cd4f1.png";
import Instagramicon from "../../image/instagramicon.png";
import Youtubeicon from "../../image/youtube icon filled.929b32d7d202eb3e0bc1.png";
import Brochure from "../../image/Bandson Windows E Brochure.pdf";
import { Link } from "react-router-dom";
import BrochureForm from "../BrochureForm";

const Mainpage = () => {
  const contentStyle = {
    textAlign: "center",
    minHeight: 120,
    ineHeight: "120px",
    color: "#fff",
    backgroundColor: "#108ee9",
  };
  const downloadBrochure = () => {
    // You can replace the URL with the actual path to your brochure file
    const brochureUrl = Brochure;

    // Create a link element
    const link = document.createElement("a");

    // Set the href attribute to the brochure URL
    link.href = brochureUrl;

    // Set the download attribute to specify the default download file name
    link.download = Brochure;

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click event on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };
  return (
    <div>
      <Layout>
        <Content>
          <Row>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
              {/* col1 */}
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Content className="maincontent">
                <img src={logo} alt="" className="logomain mb-2" /> 
                <br />
                {/* <br /> */}
                {/* <h3 className="texttitle">Windows & Door Systems</h3> */}
                <br />
                <Link to="/Home">
                  <Button type="" className="buttonstyle">
                    <span className="buttontext">Visit Website</span>
                  </Button>
                </Link>
                <br />
                <br />
                {/* <Button
                  type=""
                  className="buttonstyle" */}
                  // onClick={downloadBrochure}
                {/* > */}
                  {/* <span className="buttontext">Download Brochure</span> */}
                  <BrochureForm  className="buttonstyle" />
                {/* </Button> */}
                <br />
                <br />
                {/* <a href="tel:9257785656"> */}
                <Link to="/Contact">
                  <Button type="" className="buttonstyle mb-5">
                    <span className="buttontext">Become a Dealer</span>
                  </Button>
                </Link>
                {/* </a> */}

                <div>
                  <img src={Facebookicon} alt="" className="icons mx-2" />
                  <a
                    href="https://www.instagram.com/studiobuiltech/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Instagramicon} alt="" className="icons mx-2" />
                  </a>
                  <a
                    href="https://www.youtube.com/@Build-Tech-Solutions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Youtubeicon} alt="" className="icons mx-2" />
                  </a>
                </div>
              </Content>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
              {/* col3 */}
            </Col>
          </Row>
        </Content>
      </Layout>
    </div>
  );
};

export default Mainpage;
