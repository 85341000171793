import React, { useEffect, useState, useRef } from "react";
import image from "../../image/zazzimage.JPG";
import banimage1 from "../../image/banson-2.jpg";
import banimage2 from "../../image/banson-3.jpg";
import banimage3 from "../../image/banson-4.jpg";
import banimage4 from "../../image/banson-5.jpg";
import banimage5 from "../../image/banson-6.jpg";
import imagesazazz from "../../image/Zazz-image1.jpg";
import imagesss from "../../image/Zazzimage2.jpg";
import strings from "../../strings";
import anime from "animejs";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Product.css";
import { fetchProductById } from "../Helper/header";
import { Col, Row } from "antd";
// const Url = "https://buildtectapi.devminds.co.in/Images/";
const Url = strings.API_ROUTE + "/Images/" ;
const Product = () => {
  let { id } = useParams();
  const [productData, setProductData] = useState({});
  useEffect(() => {
    fetchProductByIdFun();
    // console.log("id", id);
  }, [id]);
  const fetchProductByIdFun = () => {
    fetchProductById({ id }).then(
      (res) => {
        setProductData(res);
      },
      (err) => {
        // console.log(err);
      }
    );
  };
  const canvasRef = useRef(null);
  window.human = false;

  useEffect(() => {
    const canvasEl = canvasRef.current;
    const ctx = canvasEl.getContext("2d");
    const numberOfParticules = 30;
    let pointerX = 0;
    let pointerY = 0;
    const tap =
      "ontouchstart" in window || navigator.msMaxTouchPoints
        ? "touchstart"
        : "mousedown";
    const colors = ["#FF1461", "#18FF92", "#5A87FF", "#FBF38C"];

    function setCanvasSize() {
      canvasEl.width = window.innerWidth * 2;
      canvasEl.height = window.innerHeight * 2;
      canvasEl.style.width = window.innerWidth + "px";
      canvasEl.style.height = window.innerHeight + "px";
      ctx.scale(2, 2);
    }

    function updateCoords(e) {
      pointerX = e.clientX || e.touches[0].clientX;
      pointerY = e.clientY || e.touches[0].clientY;
    }

    function setParticuleDirection(p) {
      const angle = anime.random(0, 360) * (Math.PI / 180);
      const value = anime.random(50, 180);
      const radius = [-1, 1][anime.random(0, 1)] * value;
      return {
        x: p.x + radius * Math.cos(angle),
        y: p.y + radius * Math.sin(angle),
      };
    }

    function createParticule(x, y) {
      const p = {};
      p.x = x;
      p.y = y;
      p.color = colors[anime.random(0, colors.length - 1)];
      p.radius = anime.random(16, 32);
      p.endPos = setParticuleDirection(p);
      p.draw = function () {
        ctx.beginPath();
        ctx.arc(p.x, p.y, p.radius, 0, 2 * Math.PI, true);
        ctx.fillStyle = p.color;
        ctx.fill();
      };
      return p;
    }

    function createCircle(x, y) {
      const p = {};
      p.x = x;
      p.y = y;
      p.color = "#FFF";
      p.radius = 0.1;
      p.alpha = 0.5;
      p.lineWidth = 6;
      p.draw = function () {
        ctx.globalAlpha = p.alpha;
        ctx.beginPath();
        ctx.arc(p.x, p.y, p.radius, 0, 2 * Math.PI, true);
        ctx.lineWidth = p.lineWidth;
        ctx.strokeStyle = p.color;
        ctx.stroke();
        ctx.globalAlpha = 1;
      };
      return p;
    }

    function renderParticule(anim) {
      for (let i = 0; i < anim.animatables.length; i++) {
        anim.animatables[i].target.draw();
      }
    }

    function animateParticules(x, y) {
      const circle = createCircle(x, y);
      const particules = [];
      for (let i = 0; i < numberOfParticules; i++) {
        particules.push(createParticule(x, y));
      }
      anime
        .timeline()
        .add({
          targets: particules,
          x: (p) => p.endPos.x,
          y: (p) => p.endPos.y,
          radius: 0.1,
          duration: anime.random(1200, 1800),
          easing: "easeOutExpo",
          update: renderParticule,
        })
        .add({
          targets: circle,
          radius: anime.random(80, 160),
          lineWidth: 0,
          alpha: {
            value: 0,
            easing: "linear",
            duration: anime.random(600, 800),
          },
          duration: anime.random(1200, 1800),
          easing: "easeOutExpo",
          update: renderParticule,
          offset: 0,
        });
    }

    const render = anime({
      duration: Infinity,
      update: function () {
        ctx.clearRect(0, 0, canvasEl.width, canvasEl.height);
      },
    });

    const handleClick = (e) => {
      window.human = true;
      render.play();
      updateCoords(e);
      animateParticules(pointerX, pointerY);
    };

    const centerX = window.innerWidth / 2;
    const centerY = window.innerHeight / 2;

    function autoClick() {
      if (window.human) return;
      animateParticules(
        anime.random(centerX - 50, centerX + 50),
        anime.random(centerY - 50, centerY + 50)
      );
      anime({ duration: 200 }).finished.then(autoClick);
    }

    document.addEventListener(tap, handleClick, false);
    autoClick();
    setCanvasSize();
    window.addEventListener("resize", setCanvasSize, false);

    return () => {
      document.removeEventListener(tap, handleClick, false);
    };
  }, []);
  return (
    <>
      <div
        id="demo"
        className="carousel slide cartext-sb22"
        data-ride="carousel"
      >
        <ul className="carousel-indicators">
          {/* <!-- <li data-target="#demo" data-slide-to="0" className="active"></li> -->
          <!-- <li data-target="#demo" data-slide-to="1"></li> -->
          <!-- <li data-target="#demo" data-slide-to="2"></li> --> */}
        </ul>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src={Url + productData.mainImage}
              className="imgSlider"
              alt="New York"
            />
            {/* <!-- <div className="carousel-caption">
              <h3>UPVC Sliding Door</h3>
              <p>UPVC sliding sash windows keep out heat, pollution, dust, and noise.</p>
            </div>   --> */}
          </div>
        </div>
      </div>
      <div className="container-fluid" style={{ paddingTop: "50px" }}>
        <h1 className="text-dark sb-22-title mt-1" style={{ marginLeft: "3%" }}>
          {productData.name}
        </h1>
      </div>
      {/* <!-- end of carousel --> */}

      {/* <!-- start of about us --> */}
      <div style={{ paddingBottom: "20px", backgroundColor: "white" }}>
        {/* <!-- <div className="container-fluid" style="padding-top:50px;">
       <h1 className="text-white" style="margin-left:5%;"> About Us </h1>
      </div> --> */}

        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <h3
                className="text-dark font-weight-italic "
                style={{
                  paddingTop: "30px",
                  fontSize: "27px",
                  fontWeight: "400",
                }}
              >
                {productData.description1}
              </h3>
              <br />{" "}
              <h3
                className="text-dark font-weight-italic "
                style={{
                  paddingTop: "30px",
                  fontSize: "27px",
                  fontWeight: "400",
                }}
              >
                {productData.description2}
              </h3>
              <br />
              <p>
                {/* <!-- <span className="text-dark font-weight-italic " style="padding-top: 30px; font-size: 28px; font-weight: 400;"> The salamander system was designed to deliver a minimalistic look with the world’s sleekest interlock system. After years of exposure and experience in international and local markets,
          salamander Windows has been able to engineer an affordable premium sliding window. 
            The system comes equipped with hardware and accessories from Premium European Brands.
          </span> --> */}
              </p>
              <br></br> <br></br>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div style={{ position: "relative", width: "100%" }}>
                <canvas
                  ref={canvasRef}
                  className="fireworks bulbule"
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%", // Adjust the width as needed
                    height: "100%", // Adjust the height as needed
                    pointerEvents: "none", // Prevent the canvas from capturing mouse events
                    justifyContent: "center",
                  }}
                ></canvas>
              </div>

              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed title-Accordion"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      Technical specification
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul className="list-technical">
                        <li>{productData.technicalSpeci}</li>
                        {/* <li>
                          {" "}
                          Miter joints (45°) with specially designed corner
                          cleats.
                        </li>
                        <li> Sleek 20 mm reinforced interlock </li>
                        <li>
                          {" "}
                          The glass specification for SB-Zazz ranges from 5mm
                          glass to 8mm glass.
                        </li>
                        <li>
                          With its features of structurally bonded glass with
                          Special bonding agent to the framing, for its size it
                          does surprise one, and defy the structural
                          stability/resistance to deflection..{" "}
                        </li>
                        <li>
                          {" "}
                          *Zazz premium can accommodate Multi point locking
                          features !! Achieves economical of scale and budget
                          for mass requirements.
                        </li>
                        <li> Choice of colors, handle, wood finish etc.</li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>
      </div>
      {/* <!-- end of about us --> */}
      <div style={{ paddingBottom: "20px", backgroundColor: "white" }}>
        <div style={{ paddingBottom: "20px", backgroundColor: "white" }}>
          <div className="container">
            <div className="row">
              {productData.techimg &&
                productData.techimg.length > 0 &&
                productData.techimg.map((e) => {
                  return (
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                      <img src={Url + e.image} className="w-100 m-1" />
                      {/* <h6>2 track</h6> */}
                    </div>
                  );
                })}

              {/* <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12  ">
                  <img src="./images/products//salamender/windows/evolutionDrive602.jpg" className="w-100 m-1" /> 
                  <h6>2,5 track</h6>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                  <img src="./images/products//salamender/windows/evolutionDrive603.jpg" className="w-100 m-1" /> 
                  <h6>3 track</h6>
              </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                  <img src="./images/products//salamender/windows/evolutionDrive604.jpg" className="w-100 m-1" /> 
                  <h6>Fixed and slide system</h6>
            </div> */}
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="mb-5">
              {/* <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <img src={imagesss} className="w-100" alt="" />
                <br></br> <br></br>
              </div> */}
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"></div>

              <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                <h3 className="text-dark similar-text">
                  SIMILAR PRODUCT RANGE
                </h3>
              </div>

              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
            </div>
            {productData.similarPro ? (
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div style={{ border: "1px solid #c3b7b7" }}>
                  <img
                    src={Url + productData.similarPro.mainImage}
                    className="w-100"
                    alt=""
                  />
                  <div className="content mt-3 ">
                    <h2
                      className="sbheading-title "
                      style={{ fontFamily: "emoji" }}
                    >
                      {productData.similarPro.name}
                    </h2>
                    <span className="paregraph-sbheading ">
                      {productData.similarPro.description1}
                    </span>
                    <br></br>
                    <br></br>
                    <hr className="text-danger" />
                    <div>
                      {/* <a
                      href="#"
                      style={{ textDecorationLine: "none", color: "black" }}
                    > */}{" "}
                      <h4>
                        {/* >_>  */}
                        <Link to={"/product/" + productData.similarPro.id}>
                          <span
                            style={{ fontSize: "19px", fontFamily: "serif" }}
                          >
                            Explore {productData.similarPro.name}
                          </span>
                        </Link>
                      </h4>
                      {/* </a> */}
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            ) : null}

            {/* <!-- <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
        <div style="border: 1px solid #c3b7b7;"  className="mt-2">
            <img src="./images/banson-1.jpg" className="w-100" > 
            <div className="content mt-3 ">
                <h2 className="sbheading-title " style="font-family: emoji;">salamander 1</h2>
                <span className="paregraph-sbheading "> 
                    focuses on delivering smooth and robust performance. 
                    Designed to provide a modest look with a smooth interlock system, SB-33 offers a</span>
                    <br></br><br></br><br/>
                <hr className="text-danger"/>
                <div>
                  <a href="./sb33-window.html" style="text-decoration-line: none;color: black;">  <h4>>_> <span style="font-size: 19px; font-family: serif;">Explore Sliding</span></h4></a>
                </div>
                <br/>
            </div>
    
        </div>
     </div> --> */}
          </div>
        </div>
      </div>
      {/* <!-- -------- --> */}
      <br></br>
      <br></br>
      <div style={{ paddingBottom: "20px", backgroundColor: "white" }}>
        {/* <!-- <div className="container-fluid" style="padding-top:50px;">
   <h1 className="text-white" style="margin-left:5%;"> About Us </h1>
  </div> --> */}

        <div className="container-fluid">
          <div className="row">
            {productData.gallery &&
              productData.gallery.length > 0 &&
              productData.gallery.map((e, index) => {
                return index == 3 ? (
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                    <img
                      src={Url + e.image}
                      className="w-100 mb-2 imaghouvers"
                      style={{ height: "98%" }}
                      alt=""
                    />
                  </div>
                ) : (
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <img
                      src={Url + e.image}
                      className="w-100 mb-2 imaghouvers"
                      style={{ height: "98%" }}
                      alt=""
                    />
                  </div>
                );
              })}
            {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <img
                src={banimage1}
                className="w-100 mb-2 imaghouvers"
                style={{ height: "98%" }}
                alt=""
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <img src={banimage2} className="w-100  mb-2 imaghouvers" alt="" />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <img
                src={banimage3}
                className="w-100  mb-2 imaghouvers"
                style={{ height: "98%" }}
                alt=""
              />
            </div>

            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
              <img src={banimage4} className="w-100  mb-2 imaghouvers" alt="" />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <img
                src={banimage5}
                className="w-100  mb-2 imaghouvers"
                style={{ height: "98%" }}
                alt=""
              />
            </div> */}
          </div>
        </div>
        <br></br>
      </div>
    </>
  );
};

export default Product;
