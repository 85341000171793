import React, { useState, useRef } from "react";
import { Form, Input, Button, Row, Col, Modal } from "antd"; // Import Ant Design components
import swal from "sweetalert"; // Import SweetAlert
import Brochure from "../../image/Bandson Windows E Brochure.pdf"; // Import your brochure file path
import { brochurefromsubmitByApi } from "../../components/Helper/brochure";
import "./brochure.css";
import emailjs from "@emailjs/browser";

const BrochureForm = () => {
  const [form] = Form.useForm(); // Form instance for Ant Design form
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formRef = useRef(); // Create a ref for the form

  const [formValues, setFormValues] = useState({
    from_name: "",
    email: "",
    mobileNoo: "",
    cityName: "",
    message: "",
    message: "Download Brochure", // Set default message
  });
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const sendEmail = (values) => {
    // console.log("Sending email with values:", values);

    if (
      formValues.message &&
      formValues.from_name &&
      formValues.email &&
      formValues.cityName &&
      formValues.mobileNoo
    ) {
      const serviceID = "service_55oi00g";
      const templateID = "template_bbqn7yk";
      const APIkeys = "eM1imwF23bPFETC4Q";
      const formElement = document.getElementById("form");

      emailjs.sendForm(serviceID, templateID, formElement, APIkeys).then(
        (response) => {
          // console.log("Email sent successfully!", response);
          setLoading(false);
          // alert("Successfully sent email");
          document.getElementById("form").reset();
            formElement.reset();
        },
        (error) => {
          // console.error("Failed to send email", error);
          // alert("Failed to send email: " + error.text);
          setLoading(false);
        }
      );
    } else {
      alert("Please fill in all the required fields.");
      setLoading(false);
    }
  };

  const onFinish = (values) => {
    setLoading(true);
    let payloadData = {
      ...values,
    };

    brochurefromsubmitByApi(payloadData).then(
      (res) => {
        downloadBrochure();
        sendEmail(values);
        form.resetFields();
        handleCancel();
        swal("success", "Brochure downloaded successfully", "success");
        setLoading(false);
      },
      (err) => {
        swal("err", "Enquiry Types error", "error");
        setLoading(false);
      }
    );
  };

  const downloadBrochure = () => {
    // Create a link element
    const link = document.createElement("a");

    // Set the href attribute to the brochure URL
    link.href = Brochure;

    // Set the download attribute to specify the default download file name
    link.download = "Brochure.pdf";

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click event on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  return (
    <div className="mainbrouchardiv">
      <Button type="" onClick={showModal}>
        <span className="buttontext">Download Brochure</span>
      </Button>
      <Modal
        title="Download Brochure"
        open={isModalOpen}
        onCancel={handleCancel}
        width={400}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col xs={24}>
            <Form
              form={form}
              id="form"
              name="basic"
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
              ref={formRef} // Attach ref to form
              initialValues={{
                message: "Download Brochure", // Set default message
            }}
            >
              <div className="form-group">
                <Form.Item
                  label="Name"
                  name="from_name"
                  rules={[
                    { required: true, message: "Please enter your name!" },
                  ]}
                >
                  <Input
                    className="form-control inputContact text-dark"
                    name="from_name"
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </div>

              <div className="form-group">
                <Form.Item
                  label="Email"
                  name="email"
                  // rules={[{ required: true, message: "Please enter your email!" }]}
                >
                  <Input
                    className="form-control inputContact text-dark"
                    name="email"
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </div>

              <div className="form-group">
                <Form.Item
                  label="City"
                  name="cityName"
                  rules={[
                    { required: true, message: "Please enter your city!" },
                  ]}
                >
                  <Input
                    className="form-control inputContact text-dark"
                    name="cityName"
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </div>

              <div className="form-group">
                <Form.Item
                  label="Number"
                  name="mobileNoo"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Mobile Number!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        // console.log(
                        //   "String(value).length",
                        //   String(value).length
                        // );
                        if (!value || String(value).length === 10) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Please input 10 numbers!")
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    className="form-control inputContact text-dark"
                    name="mobileNoo"
                    onChange={(value) =>
                      handleInputChange({
                        target: { name: "mobileNoo", value },
                      })
                    }
                  />
                </Form.Item>
              </div>

              <div className="form-group hidden">
                <Form.Item
                  label="Brochure"
                  name="message"
                  // rules={[{ required: true, message: "Please enter your message!" }]}
                >
                  <Input.TextArea
                    className="form-control inputContact text-dark"
                    name="message"
                    onChange={handleInputChange}
                    // disabled
                  />
                </Form.Item>
              </div>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  loading={loading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default BrochureForm;
