import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../image/logo.png";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <footer className="footer-wrapper2">
        <div className="footer-wrapper">
          <div className="footer-area footer-padding footer-space">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-xl-4 col-lg-4 col-md-4 col-ms-12 col-12">
                  <div className=" text-center">
                    <Link to="/">
                      {/* <!-- <img src="data:image/webp;base64,UklGRvgDAABXRUJQVlA4TOwDAAAvg8ALEJ9hkG2knj/T0zzINBC1bSP3+CM9AHfPMGjbRpB7pJ/sQ/n7MJEkSdGOWjgmh/9/FTpcBjUBUiESIhICIgI8AhwsDBR+3Lj+vmS7IxtUE9UoIp9lxJkgLozxht571v3vF9kCyIEiMzByIGQFRjIp0pKW5Gxarbs5v4Rs27bVtjnnXrF0RYbIFGaG//86HXJ6yy8R/Yfgto0kSZn7iqq6ytv7BfjvVmVZw89fiOmPkH0/p3Ununwwl/N3w/QiLZfVUXHNhvklqZxdc67tIGIeEW9slE+iy/O5UG8W8rIsC4+IZTScXdAxXp5JLp/S9fzizEDgAj4aXtgYX0SXGwoTMgOIGAtfbIRXosvruVJf8fNkWW5fFxpPR+CYS5d7RJ8HhbZI5l3bEok6LQBKjx1AQyX7I1s8MJePO6WDThC+WPMNWjCUfdsyiz4lByxlOl643AvDM0TSozAx9ypVCKEpHLpOuOZlWTjESiYpyzKRZiJHRkZ3c2loYYikyy+AR6WDAK+avuvQteSgO3QSgR6lDLFjtnQ51GTCeo/eEIuGSHoVN8q1FUzm0RR0EqhB0D/SIAZqmwk2zFFvYYikW11MfRJC6RFdD5AgNkQkVIilSlvRhVGqQRpyxFaNRUMkfQIxu5QC0vC1PiEfFo+HRp175qLQV5kjPkFrYYikZ9XjSYUfX0+apkI1Au38SpImOvX8Spp6xKDEoiGSroT5vlJWqkaHiACICYjFaBH9vDyDSoWYBqavtTBEkmCidFBpCR6xt5DSfajTO/Sy/p2EIZLM4alRkycFYqnCvmeAaQj64o63RRIYUsNA6xAL+jPBRMI0dQrik1o+YogkQ3iq5y1lkSD9oXlpYd50EJp83v6e7PaEpFWXzT8mP0ESpGGWLvQdb4gk0FLDmI2+VVUyIGsCfd8govOIcwcX2Ee4lUOcXX2vRZ8hkgzZaCCt+B4IGRFx5C92n5Ll2fjZpCIrpRN/qHTzdwNbxPKOVyLJeFJzdIUQWu7QhZapMCXFsGeGfWj1ZWaIJCU8Iy65gxxJlpOaWEuPPkMkKeEZaRkO87kQSaZ/NqKvL4YSSfpJTfz1Il9vjf9sRF9CQCqRpJ7UxFzKchMi6RtajNM0UYOT/XbBTSZZbTWSX1rstgMRGLa7BTCD/QoMxQJSiKRviMXlxAW2MLAhTUvZbH2gGodhsSUCm9Wwp0q7JWyXxg6cj+9psdwu6fSOwlRyVI3TYUGHvF0Np9RoT6Ysglru+UjHk8PiOBb7PR3nsNuJ7CCCWk6iw+44YNiPAOwhsGUB6xOIoJan+z2Z9sVh2q2OY+A7ADZrJrA4bHYD/NssAA==" alt="Logo"  */}
                      {/* className="footer-logo"> --> */}
                      <img src={Logo} className="footer-logo" alt="" />
                      <button id="play-button" style={{ display: "none" }}>
                        Play Audio
                      </button>
                    </Link>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-4 col-ms-12 col-12">
                  <div className="single-footer-caption ">
                    <div className="footer-title">
                      <h4 className="text-white space text-center">
                        Quick link
                      </h4>
                      <ul
                        style={{ listStyle: "none", paddingLeft: "0px" }}
                        className="text-center"
                      >
                        <li className="space">
                          <Link to="/" className="text-white linetext">
                            <i className="fa fa-home mx-2"></i> Home
                          </Link>
                        </li>
                        <li className="space">
                          <Link to="/About" className="text-white linetext">
                            <i
                              className="fa fa-user mx-2"
                              aria-hidden="true"
                            ></i>
                            About
                          </Link>
                        </li>
                        <li className="space">
                          <Link to="" className="text-white linetext">
                            <i
                              className="fa fa-window-restore mx-2"
                              aria-hidden="true"
                            ></i>
                            Products
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-ms-12 col-12">
                  <div className="single-footer-caption ">
                    <div className="footer-title">
                      <h4 className="text-white space  text-center">Service</h4>
                      <ul
                        style={{ listStyle: "none", paddingLeft: "0px" }}
                        className="text-center"
                      >
                        {/* <li className="space">
                          <Link href="/" className="text-white linetext">
                            <i
                              className="fa fa-question-circle mx-2"
                              aria-hidden="true"
                            ></i>
                            FAQ
                          </Link>
                        </li> */}
                        <li className="space">
                          <Link
                            // to="tel:9257795656"
                            to="/contact"
                            className="text-white linetext"
                          >
                            <i
                              className="fa fa-phone mx-2"
                              style={{ fontSize: "14px" }}
                            ></i>
                            Contact
                          </Link>
                        </li>
                        <li className="space">
                          <div className="footer-social mt-30 footer-justified m-auto text-center">
                            {/* <a href="#"> */}
                            <i
                              className="fa fa-facebook text-white"
                              style={{ fontSize: "25px" }}
                            ></i>
                            {/* </a> */}
                            <a
                              href="https://www.instagram.com/studiobuiltech/"
                              target="_blank"
                            >
                              <i className="fa fa-instagram text-white footer-icon"></i>
                            </a>
                            <a
                              href="https://www.youtube.com/@Build-Tech-Solutions"
                              target="_blank"
                            >
                              <i className="fa fa-youtube text-white footer-icon"></i>
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- footer last line --> */}
          <hr style={{ borderColor: " #868989" }} />
          <div className="footer-bottom-area">
            <div className="container">
              <div className="footer-border">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="footer-copy-right text-center">
                      <p className="text-white" style={{ fontSize: "15px" }}>
                        Copyright © 2023{" "}
                        <Link to="/" style={{ color: "white",textDecoration: "none" }}>
                          Studio Buildtech
                        </Link>{" "}
                        All Rights Reserved. Designed & Maintained by{" "}
                        <a
                          href="https://devminds.co.in/"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "white", textDecoration: "none" }}
                        >
                          DevMinds software
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="footer-bottom-area enqueryCallBottom"
            style={{ background: "#0851bfd9", paddingTop: "10px" }}
          >
            <div className="container">
              <div className="footer-border">
                <div className="row">
                  <div className="col-6">
                    <div className="footer-copy-right text-center">
                      <p className="text-white">
                        <Link
                          to="/Contact"
                          style={{ color: "white", fontWeight: "700" }}
                        >
                          <i
                            className="fa fa-quora"
                            style={{ fontSize: "14px" }}
                          ></i>
                          Enquiry
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="footer-copy-right text-center">
                      <p className="text-white">
                        <a
                          href="tel:9257785656"
                          style={{ color: "white", fontWeight: "700" }}
                        >
                          <i
                            className="fa fa-phone"
                            style={{ fontSize: "14px" }}
                          ></i>
                          Call
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
