import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import image1 from "../../image/about1.jpg";
import image2 from "../../image/about2.jpg";
import { aboutusByApi } from "../Helper/aboutus";
import Rotate from "react-reveal/Rotate";
import strings from "../../strings";
import Jump from "react-reveal";
import "./about.css";

const About = () => {
  const [loading, setloading] = useState(false);
  const [aboutusdata, setaboutusdata] = useState([]);
  // const Urlimg = "https://buildtectapi.devminds.co.in/Images/";
  const Urlimg = strings.API_ROUTE + "/Images/" ;
  useEffect(() => {
    onfunaboutus();
  }, []);
  const onfunaboutus = () => {
    setloading(true);
    aboutusByApi().then(
      (res) => {
        // console.log("res", res);
        // console.log("Aboutus res==>", res);
        setaboutusdata(res);
      },
      (err) => {
        // console.log(err);
      }
    );
  };
  return (
    <>
      <div style={{ paddingBottom: "20px", backgroundColor: "#777578" }}>
        <div className="container" style={{ paddingTop: "80px" }}>
          <Rotate bottom left>
            <h2 className="text-white autoplay"> About Us </h2>
          </Rotate>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              {aboutusdata && aboutusdata.length > 0 ? (
                aboutusdata.map((e, index) => {
                  return (
                    <>
                      <label
                        className="text-white font-weight-italic title1fontsize"
                        style={{ paddingTop: "30px", fonSize: "18px" }}
                      >
                        {e.title1}
                      </label>
                      <Jump duration={2000}>
                        <img
                          id="aboutImage1"
                          // src="./images/about1.jpg"
                          src={Urlimg + e.image1}
                          alt=""
                          className="about1"
                        />
                      </Jump>
                      {/* <h6 className="text-white">{e.}</h6> */}
                    </>
                  );
                })
              ) : (
                <>
                  <label
                    className="text-white font-weight-italic "
                    style={{ paddingTop: "30px", fonSize: "18px" }}
                  >
                    We at ‘Studio buildtech” have various options in our
                    assortment made with finest quality material, With the
                    growing demand of green building products that have been
                    replacing the conventional steel, wooden windows and doors.
                    We have been able to understand, design and create solutions
                    through supply and installation of U-PVC & Aluminium window
                    and door systems.
                  </label>
                  <Jump duration={2000}>
                    <img
                      id="aboutImage1"
                      // src="./images/about1.jpg"
                      src={image1}
                      alt=""
                      className="about1"
                    />
                    <h6 className="text-white">Sliding Folding Door</h6>
                  </Jump>
                </>
              )}
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              {aboutusdata.length > 0 ? (
                aboutusdata.map((e, index) => {
                  return (
                    <>
                      <label
                        className="text-white"
                        style={{
                          paddingBottom: "30px",
                          paddingTop: "30px",
                          fontSize: "18px",
                        }}
                      >
                        {e.title2}
                      </label>
                      <div>
                        <Link to="/About">
                          <button
                            type="button"
                            className="btn btn-outline-warning text-white "
                          >
                            learn More
                          </button>
                        </Link>
                        <Jump duration={2000}>
                          <img
                            id="aboutImage2"
                            //   src="./images/about2.jpg"
                            src={Urlimg + e.image2}
                            alt=""
                            className="about2"
                          />
                        </Jump>
                        {/* <h6 className="text-white">{e.}</h6> */}
                      </div>
                    </>
                  );
                })
              ) : (
                <>
                  <label
                    className="text-white"
                    style={{
                      paddingBottom: "30px",
                      paddingTop: "30px",
                      fontSize: "18px",
                    }}
                  >
                    Partnering with the leader in the fenestration industry
                    Shalamander Germany pioneers in developing and manufacturing
                    U-PVC profiles and now we have also come up with Brand
                    ‘Bandson’ Systems to cater some special requirements of our
                    valuable customers base. We have been able to cater to major
                    projects and retail market which include learning
                    institutions and government establishments, Residential and
                    Commercial Projects.
                  </label>
                  <div>
                    <a href="./Zazz.html">
                      <button
                        type="button"
                        className="btn btn-outline-warning text-white "
                      >
                        learn More
                      </button>
                    </a>
                    <Jump duration={2000}>
                      <img
                        id="aboutImage2"
                        //   src="./images/about2.jpg"
                        src={image2}
                        alt=""
                        className="about2"
                      />
                    </Jump>
                    <h6 className="text-white">Casement Door</h6>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
