import React, { useState, useEffect } from "react";
import { jumbotronByApi } from "../Helper/jumbotron";
import { Link } from "react-router-dom";
import "./Jumbotron.css";
import strings from "../../strings";

const Jumbotron = () => {
  const [loading, setloading] = useState(false);
  const [jumbotrondata, setjumbotrondata] = useState([]);
  // const Urlimg = "https://buildtectapi.devminds.co.in/Images/";
  const Urlimg = strings.API_ROUTE + "/Images/";
  useEffect(() => {
    onfunjumbotron();
  }, []);
  const onfunjumbotron = () => {
    setloading(true);
    jumbotronByApi().then(
      (res) => {
        // console.log("res", res);
        // console.log("jumbotron res==>", res);
        setjumbotrondata(res);
      },
      (err) => {
        // console.log(err);
      }
    );
  };
  return (
    <>
      <div className="jumbotron img-jambo">
        <div className="container ">
          {jumbotrondata && jumbotrondata.length > 0 ? (
            jumbotrondata.map((e, index) => {
              return (
                <div key={index}>
                  <h1 className="text-white">{e.title}</h1>
                  <p className="text-white">{e.address}</p>
                  <Link to="product/3">
                    <button className="btn btn-primary"> Products</button>
                  </Link>
                </div>
              );
            })
          ) : (
            <>
              <h1 className="text-white"> Welcome To Build-Tech Solutions </h1>
              <p className="text-white">
                Build-Tech Solutions Shop No: S 01 & 102, T D Plaza, 585 E Ward,
                Shahupuri, Vyapari Peth, Kolhapur 416001
              </p>
              <Link to="product/3">
                <button className="btn btn-primary"> Products</button>
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Jumbotron;
