import React, { useState, useEffect } from "react";
import { Button, Carousel } from "antd";
import Image1 from "../../image/slider1.jpg";
import Image2 from "../../image/slider2.jpg";
import Image3 from "../../image/slider3.jpg";
import Image4 from "../../image/slider4.jpg";
import Image5 from "../../image/slider5.jpg";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import Fade from "react-reveal";
import Pulse from "react-reveal";
import "animate.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useRef } from "react";
import strings from "../../strings";
import "./Carouselll.css";
import { bannerByApi } from "../Helper/banner";

const Carouselll = () => {
  const ref = useRef();
  const [loading, setloading] = useState(false);
  const [sliderdata, setSliderdata] = useState([]);
  // const Urlimg = "https://buildtectapi.devminds.co.in/Images/";
  const Urlimg = strings.API_ROUTE+ "/Images/";
  // console.log("sliderdata1111", sliderdata);
  useEffect(() => {
    onfunbanner();
  }, []);
  const onfunbanner = () => {
    setloading(true);
    bannerByApi().then(
      (res) => {
        // console.log("res", res);
        // console.log("slider res==>", res);
        setSliderdata(res);
      },
      (err) => {
        // console.log(err);
      }
    );
  };

  const onChange = (currentSlide) => {
    // console.log(currentSlide);
    // console.log("id", currentSlide);
    let id = currentSlide;
  };
  return (
    <div id="Home" className="div-main-carasal">
      <Carousel afterChange={onChange} ref={ref} autoplay>
        {sliderdata && sliderdata.length > 0 ? (
          sliderdata.map((e, index) => {
            return (
              <>
                <div className="carausal-dev">
                  <div className="carausal-text-div">
                    <img
                      // src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
                      src={Urlimg + e.image}
                      key={e.id}
                      className="carausal-dev"
                      alt=""
                    />

                    <div className="carousel-caption">
                      <Fade bottom big className="text-carausal">
                        <h3>
                          {e.title}
                          {/* Significantly Enhance The Energy Efficiency Of Any Room
                Complimenting The Modern Architectural Design */}
                        </h3>
                      </Fade>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <>
            <Carousel>
              <div className="carausal-dev">
                <div className="carausal-text-div">
                  <img
                    // src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
                    src={Image1}
                    className="carausal-dev animate__animated  animate__pulse animate__slower"
                    alt=""
                  />
                  <div className="carousel-caption">
                    <h3 className="text-carausal">
                      Significantly Enhance The Energy Efficiency Of Any Room
                      Complimenting The Modern Architectural Design
                    </h3>
                  </div>
                </div>
              </div>
              <div className="carausal-dev">
                <div className="carausal-text-div">
                  <img
                    // src="https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg"
                    src={Image2}
                    className="carausal-dev "
                    alt=""
                  />
                  <div className="carousel-caption">
                    <h3 className="text-carausal">
                      Keep Out Street Noise, Pollution, Rain, & Gale-Force
                      Winds. Aluminium Sliding Door
                    </h3>
                  </div>
                </div>
              </div>
              <div className="carausal-dev">
                <div className="carausal-text-div">
                  <img
                    // src="https://thumbs.dreamstime.com/b/environment-earth-day-hands-trees-growing-seedlings-bokeh-green-background-female-hand-holding-tree-nature-field-gra-130247647.jpg"
                    src={Image3}
                    className="carausal-dev "
                    alt=""
                  />
                  <div className="carousel-caption">
                    <h3 className="text-carausal">
                      Custom Made & Pre-Fabricated
                    </h3>
                  </div>
                </div>
              </div>
              <div className="carausal-dev">
                <div className="carausal-text-div">
                  <img
                    // src="https://cdn.searchenginejournal.com/wp-content/uploads/2022/06/image-search-1600-x-840-px-62c6dc4ff1eee-sej.png"
                    src={Image4}
                    className="carausal-dev "
                    alt=""
                  />
                  <div className="carousel-caption">
                    <h3 className="text-carausal">
                      Warm Sunlight, Fresh Air & A Connection To The Natural
                      World Can Instantly Change The Mood Of A Home Or Office
                    </h3>
                  </div>
                </div>
              </div>
              <div className="carausal-dev">
                <div className="carausal-text-div">
                  <img
                    // src="https://thumbs.dreamstime.com/b/environment-earth-day-hands-trees-growing-seedlings-bokeh-green-background-female-hand-holding-tree-nature-field-gra-130247647.jpg"
                    src={Image5}
                    className="carausal-dev "
                    alt=""
                  />
                  <div className="carousel-caption">
                    <h3 className="text-carausal">
                      Zazz Series By Bandson Minimalistic & Economical sliding
                    </h3>
                  </div>
                </div>
              </div>
            </Carousel>
          </>
        )}
      </Carousel>

      <div>
        <Button
          className="carausal-arrow-back arrowmain"
          // shape="circle"
          onClick={() => {
            ref.current.prev();
          }}
          icon={<LeftOutlined />}
        ></Button>
        {/* <Button 
      
      onClick={()=>{
        ref.current.goTo(0)
      }}
      >Reset
      </Button> */}

        <Button
          className="carausal-arrow-next arrowmain"
          // shape="circle"
          icon={<RightOutlined />}
          onClick={() => {
            ref.current.next();
          }}
        ></Button>
      </div>
    </div>
  );
};
export default Carouselll;
