import React, { useState, useEffect } from "react";
import Imageicon1 from "../../image/work-1.jpg";
import Imageicon2 from "../../image/work-2.jpg";
import Imageicon3 from "../../image/work-3.jpg";
import Imageicon4 from "../../image/work-4.jpg";
import Imageicon5 from "../../image/work-5.jpg";
import Imageicon6 from "../../image/work-6.jpg";
import Imageicon7 from "../../image/work-7.jpg";
import Imageicon8 from "../../image/work-8.jpg";
import Imageicon9 from "../../image/work-9.jpg";
import Imageicon10 from "../../image/work-10.jpg";
import Image1 from "../../image/imagework1.jpg";
import { scienceworkByApi } from "../Helper/scienceandwork";
import strings from "../../strings";
import Zoom from "react-reveal";
import "./Sciencework.css";

const Sciencework = () => {
  const [loading, setloading] = useState(false);
  const [Scienceworkdata, setScienceworkdata] = useState([]);
  const [middleimage, setMiddleImage] = useState(Image1);
  // const Urlimg = "https://buildtectapi.devminds.co.in/Images/";
  const Urlimg = strings.API_ROUTE + "/Images/" ;
  useEffect(() => {
    onfunSciencework();
  }, []);
  const onfunSciencework = () => {
    setloading(true);
    scienceworkByApi().then(
      (res) => {
        setScienceworkdata(res);
      },
      (err) => {
        // console.log(err);
      }
    );
  };
  return (
    <>
      <div className=" box-we section4-bg">
        <div className="container topspace">
          <h2 className="text-dark titlescience"> Science @ Work </h2>
          <h6 className="textspace">
            Our high-performance house windows add an architectural dimension to
            your home. uPVC windows in India keep out street noise, dust and
            pollution, rain and gale force winds. At the same time, they are
            energy efficient, low maintenance, and long-lasting.
          </h6>
        </div>
        <br />
        <div className="container ">
          <div className="row ">
            <div className="col-lg-3 col-xl-3 col-md-12 col-sm-12 col-12">
              {Scienceworkdata && Scienceworkdata.length > 0 ? (
                Scienceworkdata.map((e, index) => {
                  if (index <= 4) {
                    return (
                      <>
                        <div className="row">
                          <div className="col-lg-5 col-xl-5 col-md-5 col-sm-5 col-5">
                            <div className="icon-boxx">
                              <div className="icon-box ">
                                <Zoom top duration={3000}>
                                  <img
                                    id="icon-hover-1"
                                    onMouseOver={() => {
                                      setMiddleImage(Urlimg + e.image2);
                                    }}
                                    // src="./images/section4/1633593754.jpg"
                                    src={Urlimg + e.image1}
                                    alt=""
                                  />
                                </Zoom>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-7 col-xl-7 col-md-7 col-sm-7 col-7">
                            <div className="icon-boxx">
                              <h5 id="icon-hover-11">
                                <span style={{ color: "green" }}>
                                  {e.title1}
                                </span>{" "}
                                <br />
                              </h5>
                            </div>
                          </div>
                        </div>
                        <br />
                      </>
                    );
                  }
                })
              ) : (
                <>
                  <div className="row">
                    <div className="col-lg-5 col-xl-5 col-md-5 col-sm-5 col-5">
                      <div className="icon-boxx">
                        <div className="icon-box ">
                          <img
                            id="icon-hover-1"
                            onmouseover="onMouseHoverImg('1639137842.jpg')"
                            // src="./images/section4/1633593754.jpg"
                            src={Imageicon1}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 col-xl-7 col-md-7 col-sm-7 col-7">
                      <div className="icon-boxx">
                        <h5 id="icon-hover-11">
                          <span style={{ color: "green" }}>SAVE</span> <br />
                          ENERGY
                        </h5>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-lg-5 col-xl-5 col-md-5 col-sm-5 col-5">
                      <div className="icon-box icon-boxx">
                        <img
                          id="icon-hover-2"
                          onmouseover="onMouseHoverImg('1639137905.jpg')"
                          //   src="./images/section4/1633593687.jpg"
                          src={Imageicon2}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-7 col-xl-7 col-md-7 col-sm-7 col-7">
                      <div className="icon-boxx">
                        <h5 id="icon-hover-22">
                          <span style={{ color: "green" }}>NOISE</span> <br />{" "}
                          INSULATION
                        </h5>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-lg-5 col-xl-5 col-md-5 col-sm-5 col-5">
                      <div className="icon-box icon-boxx">
                        <img
                          id="icon-hover-3"
                          onmouseover="onMouseHoverImg('1639137943.jpg')"
                          //   src="./images/section4/1633593784.jpg"
                          src={Imageicon3}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-7 col-xl-7 col-md-7 col-sm-7 col-7">
                      <div className="icon-boxx">
                        <h5 id="icon-hover-33">
                          <span style={{ color: "green" }}>BLOCK</span> <br />{" "}
                          RAINWATER SEEPAGE
                        </h5>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-lg-5 col-xl-5 col-md-5 col-sm-5 col-5">
                      <div className="icon-box icon-boxx">
                        <img
                          id="icon-hover-4"
                          onmouseover="onMouseHoverImg('1639137997.jpg')"
                          //   src="./images/section4/1633593814.jpg"
                          src={Imageicon4}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-7 col-xl-7 col-md-7 col-sm-7 col-7">
                      <div className="icon-boxx">
                        <h5 id="icon-hover-44">
                          <span style={{ color: "green" }}>STORM</span> <br />{" "}
                          RESISTANT
                        </h5>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-lg-5 col-xl-5 col-md-5 col-sm-5 col-5">
                      <div className="icon-box icon-boxx">
                        <img
                          id="icon-hover-5"
                          onmouseover="onMouseHoverImg('1639138042.jpg')"
                          //   src="./images/section4/1633593885.jpg"
                          src={Imageicon5}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-7 col-xl-7 col-md-7 col-sm-7 col-7">
                      <div className="icon-boxx">
                        <h5 id="icon-hover-55">
                          <span style={{ color: "green" }}>LOW</span> <br />{" "}
                          MAINTENANCE
                        </h5>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-12">
              <div>
                <img
                  id="icon-middle-section-img"
                  //   src="./images/products/middle.jpg"
                  src={middleimage}
                  className="section-4-img"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-3 col-xl-3 col-md-12 col-sm-12 col-12">
              {Scienceworkdata.length > 0 ? (
                Scienceworkdata.map((e, index) => {
                  if (index > 4) {
                    return (
                      <>
                        <div className="row">
                          <div className="col-lg-5 col-xl-5 col-md-5 col-sm-5 col-5">
                            <div className="icon-boxx">
                              <div className="icon-box ">
                                <Zoom top duration={3000}>
                                  <img
                                    id="icon-hover-1"
                                    onMouseOver={() => {
                                      setMiddleImage(Urlimg + e.image2);
                                    }}
                                    // src="./images/section4/1633593754.jpg"
                                    src={Urlimg + e.image1}
                                    alt=""
                                  />
                                </Zoom>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-7 col-xl-7 col-md-7 col-sm-7 col-7">
                            <div className="icon-boxx">
                              <h5 id="icon-hover-11">
                                <span style={{ color: "green" }}>
                                  {e.title1}
                                </span>{" "}
                                <br />
                              </h5>
                            </div>
                          </div>
                        </div>
                        <br />
                      </>
                    );
                  }
                })
              ) : (
                <>
                  <div className="row">
                    <div className="col-lg-5 col-xl-5 col-md-5 col-sm-5 col-5">
                      <div className="icon-box icon-boxx">
                        <img
                          id="icon-hover-6"
                          onmouseover="onMouseHoverImg('1639138080.jpg')"
                          //   src="./images/section4/onhover1629111408.jpg"
                          src={Imageicon6}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-7 col-xl-7 col-md-7 col-sm-7 col-7">
                      <div className="icon-boxx">
                        <h5 id="icon-hover-66">
                          <span style={{ color: "green" }}>KEEP</span> <br />{" "}
                          INSECTS OUT
                        </h5>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-lg-5 col-xl-5 col-md-5 col-sm-5 col-5">
                      <div className="icon-box icon-boxx">
                        <img
                          id="icon-hover-7"
                          onmouseover="onMouseHoverImg('1639138169.jpg')"
                          //   src="./images/section4/1633594207.jpg"
                          src={Imageicon7}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-7 col-xl-7 col-md-7 col-sm-7 col-7">
                      <div className="icon-boxx">
                        <h5 id="icon-hover-77">
                          <span style={{ color: "green" }}>PREVENT</span> <br />{" "}
                          DUST BUILD UP
                        </h5>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-lg-5 col-xl-5 col-md-5 col-sm-5 col-5">
                      <div className="icon-box icon-boxx">
                        <img
                          id="icon-hover-8"
                          onmouseover="onMouseHoverImg('1639138194.jpg')"
                          //   src="./images/section4/1633594560.jpg"
                          src={Imageicon8}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-7 col-xl-7 col-md-7 col-sm-7 col-7">
                      <div className="icon-boxx">
                        <h5 id="icon-hover-88">
                          <span style={{ color: "green" }}>EASY</span> <br />{" "}
                          CUSTOMIZATION
                        </h5>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-lg-5 col-xl-5 col-md-5 col-sm-5 col-5">
                      <div className="icon-box icon-boxx">
                        <img
                          id="icon-hover-9"
                          onmouseover="onMouseHoverImg('1639138210.jpg')"
                          //   src="./images/section4/1633594654.jpg"
                          src={Imageicon9}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-7 col-xl-7 col-md-7 col-sm-7 col-7">
                      <div className="icon-boxx">
                        <h5 id="icon-hover-99">
                          <span style={{ color: "green" }}>WEATHER</span> <br />
                          RESISTANT
                        </h5>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row" style={{ marginTop: "21px" }}>
                    <div className="col-lg-5 col-xl-5 col-md-5 col-sm-5 col-5">
                      <div className="icon-box icon-boxx">
                        <img
                          id="icon-hover-10"
                          onmouseover="onMouseHoverImg('1639138226.jpg')"
                          //   src="./images/section4/1633593962.jpg"
                          src={Imageicon10}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-7 col-xl-7 col-md-7 col-sm-7 col-7">
                      <div className="icon-boxx">
                        <h5 id="icon-hover-110">
                          <span style={{ color: "green" }}>ELEGANT</span> <br />{" "}
                          LOOKS
                        </h5>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sciencework;
