import React, { useState, useEffect } from "react";
import { Button, Drawer } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../image/logo.png";
import Logobkack from "../../image/logo_black.png";
import { Link, useNavigate } from "react-router-dom";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import ReactAudioPlayer from "react-audio-player";
import Audiosong from "../../image/audio/backgroundmusic.mp3";
import { fetchAllCategoriesByApi } from "../Helper/header";
import strings from "../../strings";
import "./header.css";
// const { Link } = Anchor;

const Header = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [bgColor, setBgColor] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const listenScrollEvent = (e) => {
    if (window.scrollY > 200) {
      setBgColor(true);
    } else {
      setBgColor(false);
    }
  };
  useEffect(() => {
    listenScrollEvent();
    window.addEventListener("scroll", listenScrollEvent);
  });
  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      let position = element.getBoundingClientRect();
      // scrolls to 20px above element
      window.scrollTo(position.left, position.top + window.scrollY - 70);
    }
  };
  const handleClikHome = () => {
    navigate("/");
    setOpen(false);
  };
  const handleClikAbout = () => {
    navigate("/About");
    setOpen(false);
  };
  const handleClikProduct = () => {
    navigate("/Zazz");
  };
  const handleClikcontact = () => {
    navigate("/Contact");
    setOpen(false);
  };

  const [headerData, setheaderData] = useState([]);
  // const Urlimg = "https://buildtectapi.devminds.co.in/Images/";
  const Urlimg = strings.API_ROUTE + "/Images/" ;
  
  useEffect(() => {
    fetchheaderData();
    
  }, []);
  const fetchheaderData = async() => {
    fetchAllCategoriesByApi().then(
     async (res) => {
        // console.log("res", res);
        let result = res;
        let data = [];
        await result.maincategory.forEach((m, index) => {
              const mainCategory = { ...m, category: [] };
              result.category.forEach((c) => {
                  if (m.id === c.mainCategoryId) {
                      const category = { ...c, subcategory: [] };
                      result.subcategory.forEach((s) => {
                          if (m.id === s.mainCategoryId && c.id === s.categoryId) {
                              const subcategory = { ...s, products: [] };
                              result.products.forEach((p) => {
                                  if (m.id === p.mainCategoryId && c.id === p.categoryId && s.id === p.subCategoryId) {
                                      subcategory.products.push({ ...p });
                                  }
                              });
                              category.subcategory.push(subcategory);
                          }
                      });
                      mainCategory.category.push(category);
                  }
              });
              data.push(mainCategory);
          });
         setheaderData(data);
        
      },
      (err) => {
        // console.log(err);
      },
    );
  };
  return (
    <div>
      <div
        className={bgColor ? "main-header-bg-scroll" : "main-header-bg"}
        id="header"
      >
        <div className="container-fluid desktopheard">
          <div className="header">
            <div className="logo">
              <a href="#Home">
                {" "}
                {
                  <img
                    alt="example"
                    src={bgColor ? Logobkack : Logo}
                    className="logo-set"
                  />
                }
              </a>
            </div>
            <div className="header-banner">
              {/* <ReactAudioPlayer src={Audiosong}  autoPlay loop /> */}
              {/* <ReactAudioPlayer src={Audiosong} autoPlay loop> */}
              <label
                // id="play-button"
                className={
                  bgColor
                    ? "ant-anchor-link-title-scroll-head"
                    : "ant-anchor-link-title-scroll"
                }
                onClick={handleClikHome}
              >
                <i className="fa fa-home mx-1"></i>Home
              </label>
              {/* </ReactAudioPlayer> */}
              <label
                className={
                  bgColor
                    ? "ant-anchor-link-title-scroll-head"
                    : "ant-anchor-link-title-scroll"
                }
                onClick={handleClikAbout}
              >
                <i className="fa fa-user mx-1" aria-hidden="true"></i> About
              </label>
              <label
                className={
                  bgColor
                    ? "ant-anchor-link-title-scroll-head"
                    : "ant-anchor-link-title-scroll"
                }
                onClick={showDrawer}
              >
                <i className="fa fa-window-restore mx-1" aria-hidden="true"></i>
                Product
              </label>
              <label
                className={
                  bgColor
                    ? "ant-anchor-link-title-scroll-head"
                    : "ant-anchor-link-title-scroll"
                }
                onClick={handleClikcontact}
              >
                <i
                  className="fa fa-phone mx-1"
                  style={{ fontSize: "14px" }}
                ></i>{" "}
                Contact
              </label>
              {/* <label
                className={
                  bgColor
                    ? "ant-anchor-link-title-scroll-head"
                    : "ant-anchor-link-title-scroll"
                }
                onClick={handleClikcontact}
              >
                +919257785656
              </label> */}
            </div>
          </div>
        </div>
        <div className="mobileVisible">
          <div className="mobilehead-main">
            <div className="logo-mobile">
              {/* <Link to="http://www.google.com"> */}{" "}
              {
                <img
                  alt="example"
                  src={bgColor ? Logobkack : Logo}
                  className="logo-set"
                />
              }
              {/* </Link> */}
            </div>
            <Button type="" className="mobilehead-right" onClick={showDrawer}>
              {/* <i className="fa fa-bars bars" ></i>  */}
              <FontAwesomeIcon
                icon={faBars}
                className={bgColor ? "bar-icon-scroll" : "bar-icon"}
              />
            </Button>
          </div>
          <Drawer placement="right" onClose={onClose} open={open}>
            <label className="drowerscrolltext" onClick={handleClikHome}>
              Home
            </label>
            <br />
            <br />
            <label className="drowerscrolltext" onClick={handleClikAbout}>
              About
            </label>
            <br />
            <br />
            {/* <label
              className="ant-anchor-link-title-scroll"
              onClick={() => {
                handleClickScroll("Services");
                setOpen(false);
              }}
            >
              Product
            </label> */}
            <a
              href="#ProductMain"
              data-toggle="collapse"
              aria-expanded="true"
              className="dropdown-toggle drowerscrolltext"
            >
              Product
            </a>
            <ul
              className="list-unstyled components collapse drowerscrolltext"
              id="ProductMain"
              style={{ marginLeft: "15px" }}
            >
              {headerData && headerData.length > 0 &&
                headerData.map((e, index) => {
                  return (
                    <li className="list-lii">
                      <a
                        href={"#homeSubmenu" + e.id + index}
                        data-toggle="collapse"
                        aria-expanded="false"
                        className="dropdown-toggle drowerscrolltext"
                      >
                        {e.title}
                      </a>
                      <ul
                        className="collapse list-unstyled"
                        id={"homeSubmenu" + e.id + index}
                        style={{ marginLeft: "15px" }}
                      >
                        {e.category && e.category.length > 0 &&
                          e.category.map((f, index2) => {
                            return (
                              <li className="list-lii">
                                <a
                                  href={"#pageSubmenu" +e.id+ f.id + index2}
                                  data-toggle="collapse"
                                  aria-expanded="false"
                                  className="dropdown-toggle drowerscrolltext"
                                >
                                  {f.title}
                                </a>
                                <ul
                                  className="collapse list-unstyled"
                                  id={"pageSubmenu" + e.id+f.id + index2}
                                  style={{ marginLeft: "15px" }}
                                >
                                  {f.subcategory && f.subcategory.length > 0 &&
                                    f.subcategory.map((g, index3) => {
                                      return (
                                        <li className="list-lii">
                                          <a
                                            href={
                                              "#pageSubmenuA" +
                                              g.id +
                                              index3
                                            }
                                            data-toggle="collapse"
                                            aria-expanded="false"
                                            className="dropdown-toggle drowerscrolltext"
                                          >
                                            {g.title}
                                          </a>
                                          <ul
                                            className="collapse list-unstyled drowerscrolltext"
                                            id={
                                              "pageSubmenuA" +
                                              g.id +
                                              index3
                                            }
                                          >
                                            {g.products && g.products.length > 0 &&
                                              g.products.map((h, index4) => {
                                                return (
                                                  <li className="list-lii drowerscrolltext">
                                                    <Link
                                                      to={"/product/" + h.id}
                                                      onClick={onClose}
                                                    >
                                                      {" "}
                                                      {h.name}
                                                    </Link>
                                                    {/* <a href="./Zazz.html"></a> */}
                                                  </li>
                                                );
                                              })}
                                          </ul>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </li>
                            );
                          })}
                      </ul>
                    </li>
                  );
                })}{" "}
            </ul>
            {/* <ul class="list-unstyled components collapse ant-anchor-link-title-scroll" id="ProductMain" style={{"marginLeft":"15px"}}>
                  <li className="list-lii">
                      <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Bandson Aluminium</a>
                      <ul class="collapse list-unstyled" id="homeSubmenu" style={{"marginLeft":"15px"}}>
                          <li className="list-lii">
                            <a href="#pageSubmenu1" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Window System</a>
                            <ul class="collapse list-unstyled" id="pageSubmenu1" style={{"marginLeft":"15px"}}>
                                <li className="list-lii">
                                  <a href="#pageSubmenu2" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Sliding Window</a>
                   
                                  <ul class="collapse list-unstyled" id="pageSubmenu2">
                                  <li className="list-lii">
                                      <a href="./Zazz.html">Zazz</a>
                                  </li>
                                    <li className="list-lii">
                                        <a href="./sb22-window.html">SB-22 Windows</a>
                                    </li>
                                    <li className="list-lii">
                                        <a href="./sb33-window.html">SB-33 Windows</a>
                                    </li>
                                </ul>
                                </li>
                                <li className="list-lii">
                                  <a href="#pageSubmenu3" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Casement Window</a>
                   
                                  <ul class="collapse list-unstyled" id="pageSubmenu3">
                                    <li className="list-lii">
                                        <a href="./sbc-39-window.html">SBC-39</a>
                                    </li>
                                </ul>
                                </li>
                               
                            </ul>
                          </li>
        
                          <li className="list-lii">
                            <a href="#pageSubmenu4" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Door System</a>
                            <ul class="collapse list-unstyled" id="pageSubmenu4" style={{"marginLeft":"15px"}}>
                                <li className="list-lii">
                                  <a href="#pageSubmenu6" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Sliding Door</a>
                   
                                  <ul class="collapse list-unstyled" id="pageSubmenu6">
                                    <li className="list-lii">
                                        <a href="./sb22-door.html">SB-22 Door</a>
                                    </li>
                                    <li className="list-lii">
                                        <a href="./sb33door.html">SB-33 Door</a>
                                    </li>
                                </ul>
                                </li>
                                <li className="list-lii">
                                  <a href="#pageSubmenu5" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Casement Door</a>
                   
                                  <ul class="collapse list-unstyled" id="pageSubmenu5">
                                    <li className="list-lii">
                                        <a href="./sbc-45-casementdoor.html">SBC-45</a>
                                    </li>
                                </ul>
                                </li>
                               
                            </ul>
                          </li>
                        
                      </ul>
                  </li>
                
                  <li className="list-lii">
                    <a href="#homeSubmenu0" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Salamander UPVC</a>
                    <ul class="collapse list-unstyled" id="homeSubmenu0" style={{"marginLeft":"15px"}}>
                        <li className="list-lii">
                          <a href="#pageSubmenu11" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Window System</a>
                          <ul class="collapse list-unstyled" id="pageSubmenu11" style={{"marginLeft":"15px"}}>
                              <li className="list-lii">
                                <a href="#pageSubmenu22" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Sliding Window</a>
                 
                                <ul class="collapse list-unstyled" id="pageSubmenu22">
                                  <li className="list-lii">
                                      <a href="./sb22-window-salam.html">EvolutionDrive: 60</a>
                                  </li>
                                  <li className="list-lii">
                                      <a href="./sb33-window-salam.html">EvolutionDrive: SF</a>
                                  </li>
                              </ul>
                              </li>
                              <li className="list-lii">
                                <a href="#pageSubmenu33" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Casement Window</a>
                 
                                <ul class="collapse list-unstyled" id="pageSubmenu33">
                                  <li className="list-lii">
                                      <a href="./sbc-39-window-salam.html">STREAMLINE: 60</a>
                                  </li>
                              </ul>
                              </li>
                             
                          </ul>
                        </li>
        
                        <li className="list-lii">
                          <a href="#pageSubmenu44" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Door System</a>
                          <ul class="collapse list-unstyled" id="pageSubmenu44" style={{"marginLeft":"15px"}}>
                              <li className="list-lii">
                                <a href="#pageSubmenu66" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Sliding Door</a>
                 
                                <ul class="collapse list-unstyled" id="pageSubmenu66">
                                  <li className="list-lii">
                                      <a href="./sb22-door-salam.html">EvolutionDrive: 60</a>
                                  </li>
                                  <li className="list-lii">
                                      <a href="./sb33door-salam.html">EvolutionDrive: SF</a>
                                  </li>
                              </ul>
                              </li>
                              <li className="list-lii">
                                <a href="#pageSubmenu55" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Casement Door</a>
                 
                                <ul class="collapse list-unstyled" id="pageSubmenu55">
                                  <li className="list-lii">
                                      <a href="./sbc-45-casementdoor-salam.html">STREAMLINE: 60</a>
                                  </li>
                              </ul>
                              </li>
                             
                          </ul>
                        </li>
                      
                    </ul>
                </li>
                 
             </ul> */}
            <br />
            <br />
            <label className="drowerscrolltext" onClick={handleClikcontact}>
              Contact
            </label>
            <br />
            <br />
            {/* <label className="drowerscrolltext" onClick={handleClikcontact}>
              +919257785656
            </label> */}

            {/* </Anchor> */}
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default Header;
